<template>
  <div style="box-shadow: 0 0 0 0 !important" class="adminGraph">
    <v-row
      justify="center"
      class="mt-5 graphContent"
      style="margin: 0px; margin-bottom: -18px"
    >
      <DailyReportFilter @handleFilter="handleFilter" />
    </v-row>
    <div v-if="!getDataDone">
      <SliderGroup
        :options="this.options"
        @removeRow="removeRow($event)"
        style="margin-bottom: 30px"
      />

      <table-component
        itemKey="id"
        :headers="tableHeaders"
        :pagination="this.$store.state.pagination"
        :limit="$store.state.counterOfShow"
        :items="tableData"
        :loading="loading"
        v-model="page"
      >
        <template v-slot:[`item.التحكم`]="{ item }">
          <a :href="item.التحكم" style="text-decoration: none">
            <img src="@/assets/document-download-icon.svg" width="20px" height="30px" />
          </a>
        </template>
      </table-component>

      <div style="margin-top: 40px" v-if="!loading">
        <!-- <DailyReportChart :data="chartData" :headers="tableHeaders" /> -->
      </div>
    </div>
    <div v-else class="loader">
      <img src="../../../assets/Comp-2.gif" />
    </div>
  </div>
</template>

<script>
// import DailyReportChart from "./DailyReportChart";
import DailyReportFilter from "./DailyReportFilter.vue";
import SliderGroup from "@/components/Charts/SliderGroup.vue";
import Axios from "axios";

export default {
  components: {
    // DailyReportChart,
    DailyReportFilter,
    SliderGroup,
  },

  data() {
    return {
      chartData: [],
      tableData: [],
      options: [],
      showArray: [],
      filterObj: {},
      body: {},
      page: 1,
      getDataDone: false,
      loading: false,
      tableHeaders: [
        { text: "#", value: "index" },
        { text: "الاستشاري", value: "الاستشاري" },
        {
          text: "مجموع الطلبات المستلمة",
          value: "مجموع الطلبات المستلمة",
          width: "150px",
        },
        { text: "المستلمة", value: "المستلمة" },
        { text: "المميزة", value: "المميزة" },
        { text: "المتابعة", value: "المتابعة" },
        { text: "المؤرشفة", value: "المؤرشفة" },
        { text: "المرسلة", value: "المرسلة" },
        { text: "المكتملة", value: "المكتملة" },
        { text: "المحدث عليها", value: "المحدث عليها", width: "120px" },
        { text: "تم فتحها", value: "تم فتحها", width: "120px" },
        { text: "المهام المستلمة", value: "المهام المستلمة", width: "140px" },
        { text: "المهام المرسلة", value: "المهام المرسلة", width: "130px" },
        { text: "التذكيرات", value: "التذكيرات" },
        {
          text: "الطلبات المحولة منه",
          value: "الطلبات المحولة منه",
          width: "160px",
        },
        {
          text: "الطلبات المحولة إليه",
          value: "الطلبات المحولة إليه",
          width: "160px",
        },
        {
          text: "( المستقبلة - المحولة منه )",
          value: "المستقبلة - المحولة منه",
          width: "160px",
        },

        { text: "التحكم", value: "التحكم" },
      ],
    };
  },

  watch: {
    page(val) {
      this.getData(val, "loading");
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval !== oldvalue && !this.$store.state.admin_search_active) {
          this.getData(this.page);
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },

  },

  methods: {
    removeRow(headerValue) {
      let findIndex = this.tableHeaders.findIndex((item) => item.text === headerValue);

      if (findIndex !== -1) {
        this.tableHeaders.splice(findIndex, 1);
        this.showArray.splice(findIndex, 0, {
          id: findIndex,
          value: headerValue,
        });
      } else {
        let foundInsertedItem = this.options.findIndex((item) => item === headerValue);

        this.showArray.sort((a, b) => a.id - b.id);
        this.showArray.map((res, index) => {
          if (res.value === headerValue) {
            this.tableHeaders.splice(foundInsertedItem - (index - 1), 0, {
              text: headerValue,
              value: headerValue,
            });
            this.showArray.splice(index, 1);
          }
        });
      }
    },

    handleFilter(val) {
      this.body = new FormData();

      if (val.startDate) {
        this.body.append("start_date", val.startDate);
      }

      if (val.endDate) {
        this.body.append("end_date", val.endDate);
      }

      if (val.status === "استشاري نشط") {
        this.body.append("status", 1);
      }

      if (val.status === "استشاري مؤرشف") {
        this.body.append("status", 0);
      }

      if (val.range) {
        this.body.append("range", val.range);
      }

      if (val.managerId.length) {
        const managerProperties = {};

        val.managerId.forEach((item, index) => {
          this.body.append([`manager_id[${index}]`], item.id);
        });
      }

      if (val.agentId.length) {
        const managerProperties = {};

        val.agentId.forEach((item, index) => {
          this.body.append([`adviser_id[${index}]`], item.id);
        });
      }

      if (val.dayNumber) {
        this.body.append("range", val.dayNumber);
      }

      this.getData(1, "getDataDone");
    },

    getData(page, loader) {
      if (loader === "getDataDone") {
        this.getDataDone = true;
      } else if (loader === "loading") {
        this.loading = true;
      }
      this.options = [];
      this.tableData = [];
      let url = `${this.$store.state.url}/employee/admin/getAllUsers?page=${page}&per_page=${this.$store.state.counterOfShow}`;

      Axios.post(url, this.body, { headers: this.request_headers() }).then((res) => {
        this.setTableData(res.data.payload.data);
        this.setOptions();
        this.chartData = res.data.payload;
        this.$store.state.pagination = {
          limit: 3,
          count: res.data.payload.total_agents,
        };
        this.loading = false;
        this.getDataDone = false;
      });
    },

    setOptions() {
      this.tableHeaders.map((item, index) => {
        if (item.text !== "الاستشاري" && item.text !== "التحكم") {
          this.options.push(item.text);
        }
      });
    },

    setTableData(data) {
      data.map((res,index) => {
        this.tableData.push({
          "index": index+1,
          "الاستشاري": res.name,
          "مجموع الطلبات المستلمة": res.total_received_request?.count,
          "المستلمة": res.received_basket?.count,
          "المميزة": res.star_basket?.count,
          "المتابعة": res.followed_basket?.count,
          "المؤرشفة": res.archived_basket?.count,
          "المرسلة": res.sent_basket?.count,
          "المكتملة": res.completed_request?.count,
          "المحدث عليها": res.updated_request?.count,
          "تم فتحها": res.opened_request?.count,
          "المهام المستلمة": res.received_task?.count,
          "المهام المرسلة": res.replayed_task?.count,
          "التذكيرات": res.missed_reminders?.count,
          "الطلبات المحولة منه": res.move_request_from?.count,
          "الطلبات المحولة إليه": res.move_request_to?.count,
          "المستقبلة - المحولة منه": res.total_received_moved_request_arr?.count,
          "التحكم": res.report_pdf,
        });
      });
    },
  },

  created() {
    // this.getData(1, "getDataDone");
    this.$store.state.pagination = {};
  },
};
</script>
